<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">รายการยาและเวชภัณฑ์</h4>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>หมวดหมู่</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsDrug"
                v-model="selectDrug"
                item-text="name"
                item-value="value"
                label="เลือกหมวดหมู่"
                outlined
                single-line
                hide-details
                no-data-text="ยังไม่มีข้อมูลหมวดหมู่"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ประเภทยา</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsCategoryDrug"
                v-model="selectCategoryDrug"
                item-text="name"
                item-value="value"
                label="เลือกประเภทยา"
                outlined
                single-line
                hide-details
                no-data-text="กรุณาเลือกหมวดหมู่"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>กลุ่ม</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsGroup"
                v-model="selectGroup"
                item-text="name"
                item-value="value"
                label="เลือกกลุ่ม"
                outlined
                single-line
                hide-details
                no-data-text="กรุณาเลือกหมวดหมู่"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ผู้จำหน่าย</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsDealer"
                v-model="selectDealer"
                item-text="name"
                item-value="value"
                label="เลือกผู้จำหน่าย"
                outlined
                single-line
                hide-details
                no-data-text="ยังไม่มีข้อมูลผู้จำหน่าย"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ยี่ห้อ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsBrand"
                v-model="selectBrand"
                item-text="name"
                item-value="value"
                label="เลือกยี่ห้อ"
                outlined
                single-line
                hide-details
                no-data-text="ยังไม่มีข้อมูลยี่ห้อ"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ชื่อ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputName"
                label="ชื่อ"
                placeholder="ระบุชื่อ"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>รหัส</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputCode"
                label="รหัส"
                placeholder="ระบุรหัส"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ราคาทุน</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputCostPrice"
                label="ราคาทุน"
                placeholder="ระบุราคาทุน"
                suffix="บาท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>หน่วยนับ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsUnitName"
                v-model="selectUnitName"
                item-text="name"
                item-value="value"
                label="เลือกหน่วยนับ"
                outlined
                single-line
                hide-details
                no-data-text="ยังไม่มีข้อมูลหน่วยนับ"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ราคาขาย</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputSellPrice"
                label="ราคาขาย"
                placeholder="ระบุราคาขาย"
                suffix="บาท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ระบุจำนวนที่แจ้งเตือนก่อนยาหมด</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputAlertNearOutStock"
                label="จำนวนที่แจ้งเตือนก่อนยาหมด"
                placeholder="ระบุจำนวนที่แจ้งเตือนก่อนยาหมด"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ระบุอีเมลที่ส่งแจ้งเตือน</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputEmailToSendAlert"
                label="อีเมลที่ส่งแจ้งเตือน"
                placeholder="ระบุอีเมลที่ส่งแจ้งเตือน"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>ระบุจำนวนวันที่แจ้งเตือนก่อนยาหมดอายุ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputAlertNearOutStockDay"
                label="จำนวนวันที่แจ้งเตือนก่อนยาหมดอายุ"
                placeholder="ระบุจำนวนวันที่แจ้งเตือนก่อนยาหมดอายุ"
                suffix="วัน"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-baseline">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>แพทย์</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsDoctor"
                v-model="selectDoctor"
                label="เลือกแพทย์"
                outlined
                single-line
                hint="แพทย์ผู้ได้รับอนุญาตให้ใช้ยา"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>วิธีการใช้ยา</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-textarea
                v-model="inputHowToUse"
              >
                <template v-slot:label>
                  <div>
                    ระบุวิธีการใช้ยา
                  </div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="0" md="0" lg="4">
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="8" class="d-flex align-start">
              <v-container
                class="pa-0"
                fluid
              >
                <v-checkbox
                  v-model="boolDanger"
                  label="ออกฤทธิ์ต่อจิตและประสาท"
                ></v-checkbox>
              </v-container>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>รายละเอียดอื่นๆ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-textarea
                v-model="inputOtherDetail"
              >
                <template v-slot:label>
                  <div>
                    ระบุรายละเอียดอื่นๆ
                  </div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="d-flex flex-row align-center justify-end">
        <v-btn
          color="success"
          @click="createDrugAndMedical()"
          :loading="loaderSave"
          :disabled="loaderSave"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          บันทึก
        </v-btn>
        <v-btn
          color="error"
          class="ml-3"
          @click="goBack()"
          :loading="loaderCancel"
          :disabled="loaderCancel"
        >
          <v-icon class="mr-2">mdi-close-circle</v-icon>
          ยกเลิก
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loaderSave: null,
      loaderCancel: null,
      itemsDrug: [],
      itemsCategoryDrug: [],
      itemsGroup: [],
      itemsDealer: [],
      itemsBrand: [],
      itemsUnitName: [],
      itemsDoctor: [],
      selectDrug: '',
      selectCategoryDrug: '',
      selectGroup: '',
      selectDealer: '',
      selectBrand: '',
      selectUnitName: '',
      selectDoctor: '',
      inputName: '',
      inputCode: '',
      inputCostPrice: 0,
      inputSellPrice: 0,
      inputAlertNearOutStock: 0,
      inputEmailToSendAlert: '',
      inputAlertNearOutStockDay: 0,
      inputHowToUse: '',
      inputOtherDetail: '',
      boolDanger: false
    }
  },
  watch: {
    selectDrug () {
      const self = this
      if (self.selectDrug) {
        self.fetchGroup()
        self.fetchCategory()
      }
    },
    selectGroup () {
      const self = this
      if (self.selectGroup) {
        self.fetchCategory()
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.fetchType()
      self.fetchDealer()
      self.fetchBrand()
      self.fetchUnit()
      self.inputEmailToSendAlert = self.$store.state.user.email
    },
    async fetchType () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/category', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.category,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsDrug = tempFilter
      }
    },
    async fetchGroup () {
      const self = this
      var tempFilter = []
      var tempType = ''
      if (self.selectDrug) tempType = `?category=${self.selectDrug}`
      else tempType = ''
      try {
        const res = await self.axios.get(`${process.env.VUE_APP_API}/product/group${tempType}`, {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.group,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsGroup = tempFilter
      }
    },
    async fetchCategory () {
      const self = this
      var tempFilter = []
      var tempSearch = ''
      if (self.selectDrug && self.selectGroup) tempSearch = `?category=${self.selectDrug}&group=${self.selectGroup}`
      else if (self.selectDrug && self.selectGroup !== '') tempSearch = `?category=${self.selectDrug}`
      else if (self.selectDrug !== '' && self.selectGroup) tempSearch = `?group=${self.selectGroup}`
      else tempSearch = ''

      try {
        const res = await self.axios.get(`${process.env.VUE_APP_API}/product/type${tempSearch}`, {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.type,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsCategoryDrug = tempFilter
      }
    },
    async fetchDealer () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/dealer', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.dealer,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsDealer = tempFilter
      }
    },
    async fetchBrand () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/brand', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.brand,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsBrand = tempFilter
      }
    },
    async fetchUnit () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/unit', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.unit,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsUnitName = tempFilter
      }
    },
    goBack () {
      const self = this
      self.loaderSave = 'loader'
      self.loaderCancel = 'loader'

      self.selectDrug = ''
      self.selectCategoryDrug = ''
      self.selectGroup = ''
      self.selectDealer = ''
      self.selectBrand = ''
      self.selectUnitName = ''
      self.selectDoctor = ''
      self.inputName = ''
      self.inputCode = ''
      self.inputCostPrice = 0
      self.inputSellPrice = 0
      self.inputAlertNearOutStock = 0
      self.inputEmailToSendAlert = ''
      self.inputAlertNearOutStockDay = 0
      self.inputHowToUse = ''
      self.inputOtherDetail = ''
      self.boolDanger = false

      setTimeout(() => {
        self.$router.go(-1)
      }, 200)
    },
    createDrugAndMedical () {
      const self = this
      self.loaderSave = 'loader'
      self.loaderCancel = 'loader'

      setTimeout(() => {
        swal('สำเร็จ', 'เพิ่มรายการยาหรือเวชภัณฑ์สำเร็จ', 'success', {
          button: false,
          timer: 1000
        }).then(() => {
          self.loaderSave = null
          self.loaderCancel = null
          self.$router.go(-1)
        })
      }, 200)
    }
  }
}
</script>
